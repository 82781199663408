<template>
  <div>
    <div class="grid grid-cols-1 md:grid-cols-2 mb-4">
      <div class="col-span-1 flex items-center flex-wrap">
        <h2 class="text-2xl font-bold mr-3">Incomplete Orders</h2>
      </div>
      <div class="col-span-1">
        <search-form
          placeholder="Search user name, merchant name...."
          class="mb-5"
          v-model="data.query"
          @submit="$refs.table.loadAjaxData()"
        />
      </div>
    </div>
    <datatable
      :url="data.url"
      :ajax="true"
      :ajax-pagination="true"
      :columns="data.columns"
      :query="data.query"
      :fillable="false"
      ref="table"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: this.$options.resource([], {
        url: `${this.$baseurl}/admin/merchants/incomplete-orders`,
        query: '',
        columns: [
          {
            name: 'user',
            th: 'User',
            render: ({ user }) => `${user?.name} ${user?.last_name}`
          },
          {
            name: 'user.phone_no',
            th: 'User Phone No',
            render: ({ user }) => user?.phone_no
          },
          {
            name: 'merchant',
            th: 'Merchant',
            render: ({ merchant }) => merchant?.business_name
          },
          {
            name: 'merchant.phone',
            th: 'Merchant Phone Number',
            render: ({ merchant }) => merchant?.phone ?? ''
          },
          {
            name: 'order_description',
            th: 'Order Description'
          },
          {
            name: 'payment_method',
            th: 'Payment Method',
            render: order =>
              this.$options.filters.fromSlug(order?.payment_method)
          },
          {
            name: 'order_amount',
            th: 'Order Amount',
            render: order =>
              this.$options.filters.formatAmount(order?.order_amount)
          },
          {
            name: 'created_at',
            th: 'Created At',
            render: order =>
              this.$moment(order?.created_at).format('dddd, MMMM Do YYYY h:sa')
          }
        ]
      })
    };
  }
};
</script>
